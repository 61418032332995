<script setup lang="ts">
import Callout from "@/components/utils/Callout.vue";
import SafeAndSecureSplitContent from "./SafeAndSecureSplitContent.vue";
import restrictedAccessSvg from "@assets/sections/SafeAndSecure/restricted-access.svg?url"
import whitelistingSvg from "@assets/sections/SafeAndSecure/whitelisting.svg?url"
import useAosRefresh from "@/composables/useAosRefresh";
interface Props {
  loading?: HTMLImageElement['loading']
}

const props = withDefaults(defineProps<Props>(), {
  loading: 'lazy'
})

useAosRefresh()

</script>

<template>
  <section 
    id="safe-and-secure"
    class="container scroll-mt-32"
  >
    <div class="flex flex-col items-center relative mx-auto">
      <!-- Lighting effect start -->
      <div
        data-aos
        :class="[
          'bg-effect-lighting bg-brand-gradient -z-5',
          'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
          'aspect-video w-[150%] sm:w-full lg:w-1/2',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0) delay-800'
        ]"
      />
      <!-- Lighting effect end -->
      <Callout
        data-aos
        icon="i-material-symbols:shield-outline-rounded"
        class="duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5)"
      >
        {{$t('safe_and_secure')}}
      </Callout>
      <h2
        data-aos
        :class="[
          'heading-2 mt-3.5',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5) delay-200'
        ]"
      >
        {{ $t('safe_for_you') }}
      </h2>
      <p
        data-aos
        :class="[
          'text-brand-secondary leading-[150%] text-center max-w-150 mt-5',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5) delay-400'
        ]"
      >
        {{$t('safe_for_you_desc') }}
      </p>
    </div>
    <div
      :class="[
        'w-full h-full mt-15 relative gap-y-10 flex flex-col items-center justify-between',
        'md:(flex-row gap-y-0 gap-x-12 items-stretch) lg:gap-x-0',
      ]"
    >
      <div class="absolute bottom-0 w-full h-17 z-10 bg-gradient-to-t from-brand-background" /> <!-- gradient -->
      <div data-aos class="w-.5 mt-8 bg-brand-stroke/10 lg:inline-block hidden duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0) delay-600" />
      <SafeAndSecureSplitContent
        data-aos
        class="duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-10) delay-800"
        icon="i-material-symbols:document-scanner-outline-sharp"
      >
        <template #header> {{$t('ip_whitelisting')}} </template>
        <template #description>
          {{$t('ip_whitelisting_desc') }}
        </template>
        <template #img>
          <div class="min-h-40" >
            <img
              class="w-full h-auto"
              :src="whitelistingSvg"
              alt="whitelistining"
              :loading="props.loading"
            />
          </div>
        </template>
      </SafeAndSecureSplitContent>
      <div data-aos class="w-.5 mt-8 bg-brand-stroke/10 lg:inline-block hidden duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0) delay-600" />
      <SafeAndSecureSplitContent
        data-aos
        icon="i-material-symbols:shield-outline"
        class="duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-10) delay-800"
      >
        <template #header> {{ $t('restricted_access') }} </template>
        <template #description>
          {{ $t('restricted_access_desc') }}
        </template>
        <template #img>
          <div class="min-h-40" >
            <img
              class="w-full h-auto"
              :src="restrictedAccessSvg"
              alt="whitelistining"
              :loading="props.loading"
            />
          </div>
        </template>
      </SafeAndSecureSplitContent>
      <div data-aos class="w-.5 mt-8 bg-brand-stroke/10 lg:inline-block hidden duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0) delay-600" />
    </div>
  </section>
</template>
