<script setup lang="ts">

interface Props {
  icon: `i-${string}:${string}`
}

defineProps<Props>()
</script>

<template>
  <div class="max-w-80 shrink-0">
    <span :class="['text-brand-gradient_end text-4xl block', icon]" />
    <div class="mt-2.5 font-bold text-5.5 inline-block">
      <slot name="header" />
    </div>
    <p class="text-brand-secondary leading-[150%] mt-4">
      <slot name="description" />
    </p>
    <div class="mt-9.5">
      <slot name="img"/>
    </div>
  </div>
</template>